.home {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
}

.home__header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 1rem 2rem;
   width: 100%;
}

.home__header > h1 {
   font-family: 'Poppins', sans-serif;
   font-size: 1.5rem;
   color: #fff;
   font-weight: 800;
}

.home__body {
   width: 50vw;
   height: 65vh;
   margin: 1.5rem auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 0.35rem;
   /* overflow-y: auto; */
}

.home__body__empty {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #fff;
}

.home__body::-webkit-scrollbar {
   width: 5px;
   height: 5px;
}

.home__body::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   -webkit-border-radius: 10px;
   border-radius: 10px;
}

.home__body::-webkit-scrollbar-thumb {
   -webkit-border-radius: 10px;
   border-radius: 10px;
   background: rgba(255, 255, 255, 0.3);
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.home__body::-webkit-scrollbar-thumb:window-inactive {
   background: rgba(255, 255, 255, 0.3);
}

.home__footer {
   background-color: #fff;
   border-radius: 5px;
   padding: 4px 0;
}

.logoutBtn {
   position: absolute;
   bottom: 1rem;
   left: 1rem;
}

@media only screen and (max-width: 768px) {
   .home__body {
      width: 90vw;
   }

   .home__header {
      padding: 1rem;
   }

   .home__header > h1 {
      font-size: 1.2rem;
   }
}
