.button {
   position: relative;
   width: 150px;
   height: 40px;
   cursor: pointer;
   display: flex;
   align-items: center;
   border: 1px solid var(--primary-color);
   background-color: var(--primary-color);
   border-radius: 5px;
}

.button,
.button__icon,
.button__text {
   transition: all 0.3s;
}

.button .button__text {
   transform: translateX(30px);
   color: #fff;
   font-weight: 600;
   font-size: 1rem;
   font-family: 'Inter Variable', sans-serif;
}

.button .button__icon {
   position: absolute;
   transform: translateX(109px);
   height: 100%;
   width: 39px;
   background-color: var(--);
   display: flex;
   align-items: center;
   justify-content: center;
}

.button .svg {
   width: 30px;
   stroke: #fff;
}

.button:hover {
   background: var(--primary-color);
}

.button:hover .button__text {
   color: transparent;
}

.button:hover .button__icon {
   width: 148px;
   transform: translateX(0);
}

@media only screen and (max-width: 768px) {
   .button {
      width: 100px;
      height: 30px;
      text-align: center;
   }

   .button .button__text {
      font-size: 0.8rem;
      transform: translateX(0px);
      width: 100%;
   }

   .button .button__icon {
      display: none;
   }
}
