.checkBox {
   position: relative;
}

.checkBox > svg {
   position: absolute;
   top: -130%;
   left: -170%;
   width: 110px;
   pointer-events: none;
}

.checkBox * {
   box-sizing: border-box;
}

.checkBox input[type='checkbox'] {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   -webkit-tap-highlight-color: transparent;
   cursor: pointer;
   margin: 0;
}

.checkBox .cbx {
   width: 20px;
   height: 20px;
   top: calc(100px - 12px);
   left: calc(100px - 12px);
}

.checkBox .cbx input {
   position: absolute;
   top: 0;
   left: 0;
   width: 20px;
   height: 20px;
   border: 2px solid var(--secondary-color);
   border-radius: 50%;
}

.checkBox .cbx label {
   width: 20px;
   height: 20px;
   background: none;
   border-radius: 50%;
   position: absolute;
   top: 0;
   left: 0;
   transform: trasnlate3d(0, 0, 0);
   pointer-events: none;
}

.checkBox .cbx svg {
   position: absolute;
   top: 5px;
   left: 4px;
   z-index: 1;
   pointer-events: none;
}

.checkBox .cbx svg path {
   stroke: #fff;
   stroke-width: 3;
   stroke-linecap: round;
   stroke-linejoin: round;
   stroke-dasharray: 19;
   stroke-dashoffset: 19;
   transition: stroke-dashoffset 0.3s ease;
   transition-delay: 0.2s;
}

.checkBox .cbx input:checked + label {
   animation: splash-12 0.6s ease forwards;
}

.checkBox .cbx input:checked + label + svg path {
   stroke-dashoffset: 0;
}

@-moz-keyframes splash-12 {
   40% {
      background: var(--primary-color);
      box-shadow: 0 -18px 0 -8px var(--primary-color), 16px -8px 0 -8px var(--primary-color), 16px 8px 0 -8px var(--primary-color), 0 18px 0 -8px var(--primary-color),
         -16px 8px 0 -8px var(--primary-color), -16px -8px 0 -8px var(--primary-color);
   }

   100% {
      background: var(--primary-color);
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent,
         -32px -16px 0 -10px transparent;
   }
}

@-webkit-keyframes splash-12 {
   40% {
      background: var(--primary-color);
      box-shadow: 0 -18px 0 -8px var(--primary-color), 16px -8px 0 -8px var(--primary-color), 16px 8px 0 -8px var(--primary-color), 0 18px 0 -8px var(--primary-color),
         -16px 8px 0 -8px var(--primary-color), -16px -8px 0 -8px var(--primary-color);
   }

   100% {
      background: var(--primary-color);
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent,
         -32px -16px 0 -10px transparent;
   }
}

@-o-keyframes splash-12 {
   40% {
      background: var(--primary-color);
      box-shadow: 0 -18px 0 -8px var(--primary-color), 16px -8px 0 -8px var(--primary-color), 16px 8px 0 -8px var(--primary-color), 0 18px 0 -8px var(--primary-color),
         -16px 8px 0 -8px var(--primary-color), -16px -8px 0 -8px var(--primary-color);
   }

   100% {
      background: var(--primary-color);
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent,
         -32px -16px 0 -10px transparent;
   }
}

@keyframes splash-12 {
   40% {
      background: var(--primary-color);
      box-shadow: 0 -18px 0 -8px var(--primary-color), 16px -8px 0 -8px var(--primary-color), 16px 8px 0 -8px var(--primary-color), 0 18px 0 -8px var(--primary-color),
         -16px 8px 0 -8px var(--primary-color), -16px -8px 0 -8px var(--primary-color);
   }

   100% {
      background: var(--primary-color);
      box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent, 32px 16px 0 -10px transparent, 0 36px 0 -10px transparent, -32px 16px 0 -10px transparent,
         -32px -16px 0 -10px transparent;
   }
}
